<template>
  <div>
    <v-dialog v-model="windowDialog" max-width="800px">
      <v-card>
        <v-card-text>
          <v-container class="mt-4">
            <v-row>
              <v-col cols="12" md="6">
                <label for="url-expiry-picker" class="text-h6 black--text"
                  >Select Date</label
                >
                <v-date-picker
                  id="url-expiry-picker"
                  :allowed-dates="allowFutureDates"
                  v-model="date"
                  elevation="1"
                ></v-date-picker>
              </v-col>
              <v-col cols="12" md="6">
                <label for="url-expiry-time-picker" class="text-h6 black--text"
                  >Select Time</label
                >
                <v-time-picker
                  id="url-expiry-time-picker"
                  color="accent"
                  v-model="time"
                ></v-time-picker>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" @click="setDateTime">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import { allowFutureDates } from '@/utils/helper.js'

export default {
  props: {
    epoch: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  model: {
    prop: 'epoch',
    event: 'set-epoch',
  },
  data() {
    return {
      windowDialog: false,
      date: '',
      time: '',
    }
  },
  methods: {
    allowFutureDates: allowFutureDates,
    setDateTime() {
      console.log('Start epoch.. ', this.date, this.time)
      this.windowDialog = false
      console.log('Set epoch ', this.date, this.time)
      this.$emit(
        'set-epoch',
        moment(`${this.date} ${this.time}`, 'YYYY-MM-DD HH:mm').unix(),
      )
    },
    extractDateTime() {
      this.date = moment.unix(this.epoch).format('YYYY-MM-DD')
      this.time = moment.unix(this.epoch).format('HH:mm')
    },
    openDialog() {
      this.windowDialog = true
      if (!this.epoch) {
        this.date = moment().add(7, 'days').format('YYYY-MM-DD')
        this.time = moment().format('HH:mm')
      } else {
        this.extractDateTime()
      }
    },
  },
  mounted() {
    this.extractDateTime()
  },
}
</script>

<style></style>
