var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-dialog', {
    attrs: {
      "max-width": "800px"
    },
    model: {
      value: _vm.windowDialog,
      callback: function ($$v) {
        _vm.windowDialog = $$v;
      },
      expression: "windowDialog"
    }
  }, [_c('v-card', [_c('v-card-text', [_c('v-container', {
    staticClass: "mt-4"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('label', {
    staticClass: "text-h6 black--text",
    attrs: {
      "for": "url-expiry-picker"
    }
  }, [_vm._v("Select Date")]), _c('v-date-picker', {
    attrs: {
      "id": "url-expiry-picker",
      "allowed-dates": _vm.allowFutureDates,
      "elevation": "1"
    },
    model: {
      value: _vm.date,
      callback: function ($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('label', {
    staticClass: "text-h6 black--text",
    attrs: {
      "for": "url-expiry-time-picker"
    }
  }, [_vm._v("Select Time")]), _c('v-time-picker', {
    attrs: {
      "id": "url-expiry-time-picker",
      "color": "accent"
    },
    model: {
      value: _vm.time,
      callback: function ($$v) {
        _vm.time = $$v;
      },
      expression: "time"
    }
  })], 1)], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "red"
    },
    on: {
      "click": _vm.setDateTime
    }
  }, [_vm._v("Save")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }